<template>
	<div class="page page--home">
		<div class="header">
      <img src="@/assets/rabotut-logo.svg" alt="logo" class="img-label"/>
		</div>
		<div class="content">
			<div class="left-part">
				<div class="text">
					<span>Розыгрыш призов среди участников приложения «Работут»</span>
				</div>
				<span
            class="text terms"
            @click="openModal"
        >
          Условия акции
        </span>
			</div>
			<div class="right-part">
				<SuperWheel :isStart="isStart" @isStart="isStart = false" class="wheel"/>
			</div>
      <div
          class="button-conditions"
          @click="isStart = true"
      >
        Крутить
      </div>
<!--      <button @click="reset">-->
<!--        Сброс призов-->
<!--      </button>-->
		</div>
		<ModalConditions
			v-if="isModalConditions"
			@close="closeModal"
		/>
		<ModalLogin
			v-if="isModalAuth"
			@close="closeModal"
		/>
	</div>
</template>

<script>
import SuperWheel from "@/views/SuperWheel";
import Countdown from "@/components/Countdown";
import Modal from "@/components/Modal";
import BaseInput from "@/components/BaseInput";
import ModalConditions from "@/components/ModalConditions";
import ModalLogin from "@/components/ModalLogin";
import {mapActions} from "vuex";

export default {
	name: "HomePage",
	components: {ModalLogin, ModalConditions, BaseInput, Modal, SuperWheel, Countdown},
	data() {
		return {
			isModalConditions: false,
			isModalAuth: false,
      isStart: false,
		}
	},
	methods: {
		...mapActions('wheel', ['loadUser']),
    ...mapActions('wheel', ['login']),
    ...mapActions('wheel', ['resetPrizes']),
    // reset() {
    //   this.resetPrizes().then(res => {
    //     location.reload()
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
		toGiveaway() {
			const token = localStorage.getItem('token')
			if(token) {
				this.$router.push('/giveaway')
			} else {
				this.isModalAuth = true;
			}
			
		},
		openModal() {
			this.isModalConditions = true;
		},
		closeModal() {
			this.isModalConditions = false;
			this.isModalAuth = false;
		}
	},
	mounted() {
    const token = localStorage.getItem('token');
    if(!token || token.length === 0) {
      // this.$router.push('/403')
      this.isModalAuth = true
    }
	}
}
</script>

<style lang="scss">
.page--home {
	height: 100%;
	padding: 30px 24px;
	background: #0068D1;
	.header {
		display: flex;
		justify-content: center;
		align-items: center;
    width: 100%;
		.img-label {
			width: 178px;
			height: 42px;
		}
	}
	.content {
    background-image: url("../assets/star-icon.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: top right 5%;
		.left-part {
			display: flex;
      text-align: center;
			flex-direction: column;
			padding-top: 24px;
			.title {
				font-size: 2.5rem;
				color: white;
				font-weight: bold;
				padding-bottom: 12px;
				position: relative;
				@media (min-width: 680px) {
					font-size: 56px;
					padding-right: 3rem;
				}
				@media (min-width: 1024px) {
					padding-right: 0;
				}
				&-bow {
					position: absolute;
					width: 3rem;
					height: 3rem;
					top: -1rem;
					left: -1.5rem;
				}
			}
			.text {
				color: white;
				font-size: 14px;
        font-weight: 600;
			}
      .terms {
        color: #FEE18B;
        cursor: pointer;
        padding-top: 8px;
      }
			.buttons {
				margin-top: 40px;
				@media (max-width: 680px) {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
      .wheel-banner {
        margin-top: -20px;
      }
			.timer {
				position: relative;
				margin-top: 95px;
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: 230px;
				padding: 58px 58px 40px 58px;
				border-radius: 20px;
				border: 5px solid white;
				background: linear-gradient(103.75deg, #923BFF 0.97%, #9B4CFF 10.04%, #933CFF 18.55%, #933CFF 39.53%, #A257FF 46.76%, #923BFF 55.52%, #923BFF 74.61%, #A359FF 82.86%, #923BFF 89.57%, #923BFF 100%);
				@media (max-width: 680px) {
					background: none;
					border: 0;
					margin-top: 26px;
					padding: 0 58px 220px 58px;
				}
				&:before {
					content: '';
					width: 100%;
					height: 100%;
					border-radius: 20px;
					border: 5px dashed #209A1E;
					position: absolute;
					bottom: 0;
					top: -5px;
					@media (max-width: 680px) {
						display: none;
					}
				}
				&-image {
					width: 78px;
					height: 78px;
					position: absolute;
					left: 100%;
					bottom: calc(100% - 2rem);
					@media (max-width: 680px) {
						display: none;
					}
				}
			}
		}
		.right-part {
				height: 200px;
				width: 100%;
				position: absolute;
				top: 170px;
				right: 0;
      background-image: url("../assets/star-icon.png"),
      url("../assets/Star-white-icon.png");
      background-repeat: no-repeat, no-repeat;
      background-size: 20px, 46px;
      background-position: bottom right 20%, bottom left 10%;
			.wheel {
				position: relative;
				display: flex;
			}
		}
    .button-conditions {
      color: #FEE18B;
      background: #0167CF;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      cursor: pointer;
      z-index: 1900;
    }
	}
	.mobile-wreath {
		background-image: url("../assets/svg/mobile-wreath.svg");
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		height: 170px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		@media (max-width: 680px) {
			background-size: cover;
			height: 75px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		@media (min-width: 1026px) {
			display: none;
		}
	}
	.button-primary {
		padding: 20px 35px;
		color: white;
		border: 2px solid #FFFFFF;
		border-radius: 100px;
		background: #1A2819;
		font-size: 20px;
		cursor: pointer;
		font-weight: bold;
	}
	.button-text {
		padding: 20px 35px;
		color: white;
		font-weight: bold;
		background: transparent;
		border: 0;
		font-size: 20px;
		cursor: pointer;
	}
}
</style>
