<template>
  <div class="page page--end">
    <div class="header">
      <img src="@/assets/rabotut-logo.svg" alt="logo" class="img-label"/>
    </div>
    <div class="content">
      <div class="left-part">
        <div class="title">
          Ошибка 403
        </div>
        <span class="text">Отказано в доступе</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "403",
}
</script>

<style lang="scss" scoped>
.page--end {
  height: 100%;
  padding: 30px;
  background: #0068D1;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .img-label {
      width: 178px;
      height: 42px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    @media (min-width: 1025px) {
      flex-direction: row;
    }

    .left-part {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: column;
      padding-top: 50px;

      .title {
        position: relative;
        color: white;
        font-size: 42px;
        font-weight: bold;
        margin-bottom: 10px;
        &-bow {
          position: absolute;
          width: 2rem;
          height: 2rem;
          top: -0.75rem;
          left: -1rem;
          @media (min-width: 680px) {
            width: 3rem;
            height: 3rem;
            top: 0.25rem;
            left: -1.25rem;
          }
        }
      }
      .text {
        color: white;
        font-weight: bold;
        padding-right: 0;
        font-size: 20px;
        text-align: center;
        @media (min-width: 680px) {
          padding-right: 12rem;
          font-size: 20px;
        }
        @media (min-width: 1025px) {
          padding-right: 0;
        }
      }
      .box {
        max-width: 390px;
        font-size: 20px;
        color: white;
        margin-top: 20px;
        @media (min-width: 680px) {
          padding-right: 0;
          margin-top: 43px;
        }
      }
    }

    .right-part {
      position: relative;
      display: flex;
      justify-content: end;
      padding-bottom: 8rem;
      width: 60%;
      @media (max-width: 680px) {
        display: flex;
      }
      @media (min-width: 680px) {
        display: flex;
        padding-bottom: 10rem;
      }
      @media (min-width: 1025px) {
        padding-bottom: 0;
      }
      .block-bg__1 {
        width: 760px;
        height: 650px;
        background-color: #B77EFF;
        border-radius: 70px 0 0 70px;
        display: none;
        align-items: center;
        justify-content: end;
        @media (min-width: 1025px) {
          display: flex;
        }
      }
    }

  }
  .mobile-wreath {
    width: 100%;
    height: 98px;
    @media (min-width: 680px) {
      width: 100%;
      height: 200px;
      position: absolute;
    }
    @media (min-width: 1025px) {
      display: none;
    }
  }
}
</style>