<template>
  <Modal isImage @close="$emit('close')">
    <div class="notries">
      <h3 class="notries__error">
        Попытки закончились
      </h3>
      <div>
        <h3 class="notries__title">Ваш прошлый приз:</h3>
        <div v-if="prizes && prizes.length">
          <h1 class="notries__prize">{{prizes}}</h1>
          <p class="notries__text">
            Мы пришлем ваш приз в ближайшее время. Ожидайте смс
          </p>
        </div>
        <div
            v-else
            class="notries__text notries__failure"
        >
          Вы ничего не выиграли.
          Не отчаивайтесь, повезет в другой раз.
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import {mapState} from "vuex";
import PRIZES from "@/common/constants/prizes";

export default {
  name: "ModalNoTries",
  components: {BaseButton, Modal},
  computed: {
    ...mapState('wheel', ['user']),
    prizes() {
      console.log('this.user.prizes.length', this.user.prizes.length)
      if(this.user && this.user.prizes.length) {
        return PRIZES[this.user.prizes[0].id || 4]
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.notries {
  max-height: 75%;
  text-align: center;
  padding-bottom: 70px;
  background-image: url("../assets/star-yellow.svg"),
  url("../assets/star-yellow.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: 28px, 20px;
  background-position: top 20% left 2%, bottom 30% right 3%;
  &__error {
    color: #0167CF;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
  }
  &__title {
    color: #204062;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
  }
  &__prize {
    color: #0167CF;
    font-weight: 600;
    font-size: 60px;
    margin: 30px 0;
  }
  &__text {
    color: #204062;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 40px;
  }
  &__failure {
    margin-top: 20px;
  }
}
.conditions-header {
  font-size: 24px;
}
.button {
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
  @media (max-width: 376px) {
    display: flex;
    justify-content: center;
  }

  .modal-button {
    width: 200px;
  }
}

.link {
  text-decoration: none;
}

</style>