<template>
  <Modal isImage @close="$emit('close')">
    <div class="win">
      <h3 class="win__title">
        Поздравляем, вы выиграли сертификат на
      </h3>
      <h1 class="win__prize">{{prizes}}</h1>
      <p class="win__text">
        Мы пришлем ваш приз в ближайшее время. Ожидайте смс
      </p>
      <div class="phone">
        <img
            src="@/assets/phone-icon.svg"
            alt="phone-icon"
            class="phone__icon"
        />
        <input
            class="phone__input"
            type="text"
            placeholder="8 999 999 99 99"
            v-mask="'8 ### ### ## ##'"
            v-model="phone"
        />
        <div class="phone__button" @click="submit">
          <img
              src="@/assets/arrow-icon.svg"
              alt="phone-icon"
              class="icon"
          />
        </div>
      </div>
      <span
          v-if="errorPhone"
          class="error"
      >
          Не правильно указан номер
        </span>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import {mapActions, mapState} from "vuex";
import PRIZES from "@/common/constants/prizes";

export default {
  name: "ModalWin",
  components: {BaseButton, Modal},
  data() {
    return {
      phone: '',
      errorPhone: false,
    }
  },
  computed: {
    ...mapState('wheel', ['user']),
    prizes() {
      if(this.user && this.user.prizes && this.user.prizes.length) {
        return PRIZES[this.user.prizes[this.user.prizes.length - 1]?.id || 4]
      }
      return ''
    }
  },
  methods: {
    ...mapActions('wheel', ['sendAddress']),
    submit() {
      const phone = this.phone.replace(/\s/g, '');
      const form = {
        phone: phone
      }
      if(phone && phone.length === 11) {
        this.sendAddress(form)
            .then(res => {
              this.$emit('close')
              this.errorPhone = false
            }).catch(err => {
          console.log(err)
        })
      } else {
        this.errorPhone = true
        console.log('Не правильно указан номер')
      }
    }
  }
}
</script>

<style lang="scss">
.win {
  max-height: 75%;
  text-align: center;
  padding-bottom: 70px;
  background-image: url("../assets/star-yellow.svg"),
  url("../assets/star-yellow.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: 28px, 20px;
  background-position: top 20% left 2%, bottom 10% right 3%;
  margin-bottom: 40px;
  &__title {
    color: #204062;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
  }
  &__prize {
    color: #0167CF;
    font-weight: 600;
    font-size: 60px;
    margin: 30px 0;
  }
  &__text {
    color: #204062;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #204062;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  &__icon {
    width: 12px;
    height: 18px;
    margin-right: 14px;
    margin-left: 15px;
  }
  &__input {
    margin-top: 2px;
    border: none;
    font-size: 18px;
    width: 160px;
    outline: none;
    box-shadow: none;
  }
  &__input :active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E62148;
    width: 40px;
    height: 40px;
    cursor: pointer;
    .icon {
      width: 14px;
      height: 16px;
    }
  }
}

.error {
  color: red;
}

.button {
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
  @media (max-width: 376px) {
    display: flex;
    justify-content: center;
  }

  .modal-button {
    width: 200px;
  }
}

.link {
  text-decoration: none;
}

</style>