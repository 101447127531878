<template>
	<Modal
		class="modal-giveaway"
		:is-large="isLarge"
		:closable="false"
	>
		<div class="content-column">
			<img
				src="../assets/svg/modal-bg.svg"
				class="modal-bg"
			/>
			<div class="left-part">
				<div class="left-part--header">
					Поздравляем!
					<img
						src="../assets/svg/confetti.svg"
						alt="confetti"
						class="header-image"
					/>
				</div>
				<div class="mobile-wrapper">
					<div class="present-image">
						<img :src="prize.photoUrl" class="image" alt="prize"/>
					</div>
					<div class="description">
						Вы выиграли
						<span class="text-primary">{{prize.message}}</span>.
						Мы отправим ваш подарок в ближайший пункт выдачи посылок.
						Пожалуйста, заполните ваш адрес <span class="arrow">→</span>
					</div>
				</div>
				
			</div>
			<div class="right-part">
				<AddressForm @onSubmitAddress="$emit('onSubmit')"/>
			</div>
		</div>
	</Modal>
</template>

<script>
import Modal from "@/components/Modal";
import AddressForm from "@/components/AddressForm";
import BaseButton from "@/components/BaseButton";

export default {
	name: "ModalAddress",
	components: {BaseButton, AddressForm, Modal},
	props: {
		isLarge: {
			type: Boolean,
			default: false,
		},
		prize: {
			type: Object,
			default: () => {}
		}
	}
}
</script>

<style lang="scss" scoped>
.modal-giveaway {
	
	.content-column {
		display: flex;
		background-repeat: no-repeat;
		padding-bottom: 2rem;
		@media (max-width: 1024px) {
			flex-direction: column;
		}
		.modal-bg {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 0;
			color: white;
			@media (max-width: 680px) {
				max-width: 300px;
				bottom: -1.5rem;
				left: -1.5rem;
			}
			@media (min-width: 680px) {
				max-width: 300px;
				bottom: -1.5rem;
				left: -1.5rem;
			}
			@media (min-width: 1025px) {
				max-width: 300px;
				bottom: -2rem;
				left: -2.5rem;
			}
		}
		
		.left-part {
			padding-right: 2rem;
			max-width: 50%;
			border-right: 1px solid #e6e6e6;
			z-index: 10;
			@media (max-width: 1024px) {
				width: 100%;
				max-width: 100%;
				padding-right: 0;
				border: 0;
			}
			&--header {
				margin: 0 auto;
				position: relative;
				font-size: 2rem;
				padding-bottom: 40px;
			
				@media (max-width: 1024px) {
					text-align: center;
					padding-bottom: 3rem;
					padding-top: 1rem;
				}
				@media (min-width: 1024px) {
					text-align: center;
					padding-bottom: 3rem;
				}
				.header-image {
					position: absolute;
					top: -1.5rem;
					width: 3rem;
					height: 3rem;
					@media (max-width: 376px) {
						top: -0.5rem;
					}
					@media (min-width: 680px) {
						top: -0.5rem;
					}
					@media (min-width: 1025px) {
						top: -1.5rem;
					}
				}
			}
			.mobile-wrapper {
				@media (min-width: 680px) {
					display: flex;
				}
				@media (min-width: 1025px) {
					display: flex;
					flex-direction: column;
				}
				.present-image {
					margin: 0 auto;
					max-height: 175px;
					max-width: 220px;
					width: 100%;
					height: 100%;
				}
				.description {
					text-align: center;
					padding-top: 1rem;
					@media (max-width: 680px) {
						padding-bottom: 1rem;
						border-bottom: 1px solid #e6e6e6;
					}
					@media (min-width: 680px) {
						padding-top: 0;
						padding-left: 2rem;
						text-align: left;
					}
					@media (min-width: 1025px) {
						padding-top: 1rem;
						padding-left: 0;
						text-align: center;
					}
					.arrow {
						@media (max-width: 1025px) {
							transform: rotate(90deg);
							display: inline-block;
						}
					}
				}
			}
		}
		.right-part {
			width: 50%;
			padding-left: 40px;
			@media (max-width: 1024px) {
				width: 100%;
				padding-left: 0.5rem;
				padding-right: 0.5rem;
				padding-top: 2rem;
			}
		}
	}
	&--content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.present-image {
			height: 175px;
			width: 100%;
			max-height: 175px;
			max-width: 170px;
		}
		.description {
			margin-top: 1rem;
			padding-left: 2rem;
			padding-right: 2rem;
			text-align: center;
			
			@media (max-width: 680px) {
				padding-bottom: 1rem;
			}
		}
		.modal-button {
			margin-top: 50px;
			width: 200px;
		}
	}
	.text-primary {
		font-weight: bold;
		color: #8321FF;
	}
	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

</style>
