<template>
  <Modal isImage @close="$emit('close')">
    <div class="thanks">
      <h1 class="thanks__title">Спасибо!</h1>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";

export default {
  name: "ModalThanks",
  components: {BaseButton, Modal},
}
</script>

<style lang="scss">
.thanks {
  max-height: 75%;
  text-align: center;
  padding-bottom: 70px;
  background-image: url("../assets/star-yellow.svg"),
  url("../assets/star-yellow.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: 28px, 20px;
  background-position: top 5% left, bottom 40% right 3%;
  &__title {
    color: #0167CF;
    font-weight: 600;
    font-size: 60px;
    margin-top: 10px;
    padding-top: 20px;
    margin-bottom: 60px;
  }
}

</style>