<template>
	<Modal @close="$emit('close')">
		<template v-slot:header>
			<span class="conditions-header">Условия розыгрыша</span>
		</template>
		<div class="conditions">
      <p>
        Условия на участие в розыгрыше подарков, для пользователей мобильного приложения «Работут»:
      </p>
			<p>
        1. <a class="link" href="https://rabotut.ru/" target="_blank">Организатор</a> - Общество с ограниченной ответственностью «РАБОТУТ» ОГРН:   1179204006850 ИНН/КПП   9201521930/920101001.
			</p>
			<p>
        2. Срок проведения розыгрыша с 18.04.2022 по 20.04.2022 года.
      </p>
			<p>
        3. Участник должен являться  сотрудником Компании и быть пользователем приложения «Работут».
      </p>
			<p>
        4. Для участия в розыгрыше  необходимо  зайти в приложение «Работут» под своей учетной записью, нажать кнопку участвовать в розыгрыше, заполнить форму на участие (указать номер телефона) и дать согласие на обработку персональных данных.
        Участник вправе участвовать в данном розыгрыше подарков только один раз.
			</p>
			<p>
        5. Организатор разыгрывает подарки в виде электронных сертификатов на общую сумму 300 000,00(триста тысяч) рублей 00 копеек.
        Организатор не выплачивает денежный эквивалент электронного сертификата, а также не осуществляет  его замену.
			</p>
			<p>
        6. В случае возникновения технических ошибок или других вопросов по розыгрышу, обратитесь по тел. горячей линии  <a href="tel:+78005552888">8 (800) 555-28-88 </a> или напишите нам на <a href="mailto: sos@rabotut.ru">sos@rabotut.ru</a>.
      </p>
      <p>
        7. В случае выигрыша, электронная подарочная карта -   будет отправлена Вам посредством СМС на ваш номер телефона  - указанный Вами, при заполнении регистрационной формы.  Все  подарки будут направлены в течении 10- ти рабочих  дней с момента завершения розыгрыша.
      </p>
			<div class="button">
				<BaseButton
					class="modal-button"
					type="primary"
					@click="$emit('close')"
				>
					Понятно
				</BaseButton>
			</div>
		</div>
	
	</Modal>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";

export default {
	name: "ModalConditions",
	components: {BaseButton, Modal}
}
</script>

<style lang="scss">
.conditions {
	max-height: 75%;
}
.conditions-header {
  font-size: 24px;
}
.button {
	padding-top: 2.5rem;
  display: flex;
  justify-content: center;
	@media (max-width: 376px) {
		display: flex;
		justify-content: center;
	}
	
	.modal-button {
		
		width: 200px;
	}
}

.link {
	text-decoration: none;
}

</style>
