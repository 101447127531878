<template>
<div class="address-form">
	<div v-if="errors.submit" class="alert">
		{{errors.submit}}
	</div>
	<base-input
		class="form-input"
		v-model="form.city"
		:error="errors.city"
		label="Город"
		placeholder="Москва"
		@onFocus="clearError('city')"
	/>
	<div class="field-phone">
		<span class="label">Введите номер телефона</span>
		<the-mask
			id="phone"
			v-model="form.phone"
			class="input-phone"
			mask="+7-###-###-##-##"
			@input="clearError('phone')"
		/>
		<span class="error">{{errors.phone}}</span>
	</div>
	<base-input
		class="form-input"
		v-model="form.index"
		:error="errors.index"
		label="Индекс"
		placeholder="000000"
		@onFocus="clearError('index')"
	/>
	<base-input
		class="form-input"
		v-model="form.street"
		:error="errors.street"
		label="Улица"
		placeholder="Арбат"
		@onFocus="clearError('street')"
	/>
	<div class="inline">
		<base-input
			class="inline--item"
			v-model="form.house"
			:error="errors.house"
			label="Дом"
			placeholder="56"
			@onFocus="clearError('house')"
		/>
		<base-input
			class="inline--item"
			v-model="form.apartment"
			:error="errors.apartment"
			label="Квартра"
			placeholder="48"
			@onFocus="clearError('apartment')"
		/>
	</div>
	<base-button
		class="button-submit"
		@click="submit"
	>
		Отправить
	</base-button>
</div>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import BaseButton from "@/components/BaseButton";
import {mapActions, mapState} from "vuex";
import {TheMask} from 'vue-the-mask'

export default {
	name: "AddressForm",
	components: {BaseButton, BaseInput, TheMask},
	data() {
		return {
			form: {
				city: '',
				index: '',
				phone: '',
				street: '',
				house: '',
				apartment: '',
			},
			errors: {
				city: '',
				index: '',
				phone: '',
				street: '',
				house: '',
				apartment: '',
				submit: '',
			}
		}
	},
	computed: {
		...mapState('wheel', ['user']),
	},
	methods: {
		...mapActions('wheel', ['sendAddress']),
		submit() {
			this.errors.submit = '';
			for (const [key, value] of Object.entries(this.form)) {
				if(!value) {
					this.errors[key] = 'Обязательное поле'
				}
			}
			if(this.form.phone.length < 10) {
				this.errors.phone = "Недопустимый формат ввода";
			}
			if(Object.values(this.errors).some(x => x !== '')) {
				throw new Error('Форма заполнена не корректно')
			} else {
			 this.sendAddress(this.form)
				.then(() => {
					this.$emit('onSubmitAddress')
				})
				.catch((error) => {
					console.error('error', error)
					this.errors.submit = 'Ошибка отправки! Попробуйте позже.'
				})
			}
		},
		clearError(field) {
			console.log('clearError');
			if(field && field in this.errors) {
				this.errors[field] = ''
			}
		},
	},
	mounted() {
		if(this.user && this.user.address) {
			this.form = JSON.parse(JSON.stringify(this.user.address))
			this.form.phone = JSON.parse(JSON.stringify(this.user.phone))
		}
	}
}
</script>

<style lang="scss" scoped>
.address-form {
	max-width: 360px;
	@media (max-width: 1025px) {
		padding-bottom: 3rem;
	}
	@media (min-width: 680px) {
		max-width: 100%;
		width: 100%;
	}
	.form-input {
		margin-bottom: 0.75rem;
	}
	.inline {
		display: flex;
		&--item {
			max-width:calc(50% - 0.75rem);
		}
		&--item + .inline--item {
			max-width: 50%;
			margin-left: 1rem;
		}
		
	}
	.field-phone {
		display: flex;
		flex-direction: column;
		position: relative;
		padding-bottom: 1rem;
		.label {
			margin-bottom: 5px;
			color: #828282;
			font-size: 14px;
		}
		.error {
			position: absolute;
			bottom: 0;
			color: red;
			font-size: 0.75rem;
		}
		.input-phone {
			padding: 0.75rem;
			border: 0;
			border-radius: 4px;
			background: #F8F9FB;
			&:focus {
				outline: 0 !important;
				outline-offset: 0 !important;
				border: 0 !important;
				box-shadow: none !important;
			}
		}
	}
	.button-submit {
		margin-top: 3rem;
		width: 200px;
		@media (max-width: 1025px) {
			position: absolute;
			bottom: 1rem;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}
	.alert {
		color: red;
		font-size: 12px;
		padding: 0.5rem;
		background: rgba(255, 0, 0, 0.1);
		margin-bottom: 1rem;
		border-radius: 4px;
	}
}

</style>
