<template>
		<div
			class="modal__wrapper"
			@mousedown.self="onMouseDown"
		>
			<div :class="['modal-content', isImage && 'modal-image', classes]">
				<div class="modal-header">
					<slot name="header"></slot>
					<button
						v-if="closable"
						class="cursor-pointer button-close"
						@click="close"
					>
						<img src="../assets/close.png" alt="close"/>
					</button>
				</div>
				<div class="modal-body">
					<slot></slot>
				</div>
<!--        <img class="img" src="../assets/wheel.png" alt="wheel-icon">-->
			</div>
		</div>
</template>

<script>


export default {
	name: 'Modal',
	props: {
		persistent: {
			type: Boolean,
			default: true
		},
		closable: {
			type: Boolean,
			default: true
		},
		isLarge: {
			type: Boolean,
			default: false
		},
    isImage: {
      type: Boolean,
      default: false
    }
	},
	model: {
		prop: 'isModal',
		event: 'close',
	},
	computed: {
		classes() {
			return {
				'modal-content--large': this.isLarge,
			}
		}
	},
	methods: {
		onMouseDown() {
			if(this.persistent) {
				this.close()
			}
		},
		close () {
			this.$emit('close', false);
		}
	},
	mounted() {
		document.body.addEventListener('keyup', (e) => {
			if (e.keyCode === 27 && this.persistent) {
				this.close();
			}
		});
		document.body.classList.add("is-modal")
	},
	beforeDestroy() {
		document.body.classList.remove("is-modal")
	}
}
</script>

<style lang="scss" scoped>

.modal {
	z-index: 9999;
  position: relative;
}

.modal__wrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity .2s ease;
	z-index: 1998;
	background-color: rgba(00,00,00,.48);
	overflow: hidden;
}

.modal-image {
  width: 400px;
  background-image: url("../assets/modal_footer_mobile.png");
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: bottom 0 left 50%;
}

.modal-content {
	display: block;
	width: 100%;
	position: relative;
  overflow: auto;
  max-width: 544px;
  max-height: 90vh;
  padding: 1rem 1rem;
	background-color: white;
	transition: all .2s ease;
	border-radius: 8px;
	z-index: 1999;
	@media (max-width: 680px) {
		overflow: auto;
		max-width: 75vw;
		max-height: 90vh;
		padding: 1rem 1rem;
	}
	&--large {
		max-width: 75vw;
		max-height: 600px;
		overflow: auto;
		@media (min-width: 680px) {
			max-width: 75vw;
			padding: 1rem;
			max-height: 600px;
			overflow: auto;
		}
		@media (min-width: 1025px) {
			max-width: 75vw;
			max-height: 100%;
			overflow: hidden;
		}
	}
	.modal-header {
		display: flex;
		font-size: 2rem;
		padding-bottom: 1rem;
		@media (max-width: 376px) {
			font-size: 28px;
		}
	}
	
	.modal-body {
		position: relative;
	}
}

.button-close {
	position: absolute;
	transition: 0.4s;
	cursor: pointer;
	background: transparent;
	border: 0;
	right: 1.25rem;
	top: 1rem;
}

</style>
