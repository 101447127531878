<template>
</template>

<script>
import queryString from "query-string";
import {mapActions} from "vuex";

export default {
  name: "Authorizer",
  methods: {
    ...mapActions('wheel', ['onSignIn']),
    preparedAuth() {
      const parsedQuery = queryString.parse(window.location.search)
      if(parsedQuery?.accessToken) {
        const payload = {
          accessToken: parsedQuery.accessToken,
        }
        this.onSignIn(payload)
        /**
         * # case login
         * <- /login
         * -> /test-list
         *
         * # case logout
         * <- /test/123/321
         * -> /login?redirectUrl=/test/123/321
         *
         * # case logout preview
         * <- /test/123/123?mode=preview
         * -> /login?redirectUrl=/test/123/123?mode=preview
         *
         * # case google authorizer
         * <- /authorizer?token=bla&redirectUrl=/test/123/123?mode=preview
         * -> /test/123/123?mode=preview
         *
         * # case google button press simple
         * <- /login
         * -> /api/auth?redirectUrl=/authorizer
         *
         * # case google button press tricky
         * <- /test/123/123?mode=preview
         * -> /api/auth?redirectUrl=/authorizer?redirectUrl=/test/123/123?mode=preview
         */
        const redirectTo = parsedQuery?.redirectURL;
        const targetURL = new URL(redirectTo, window.location.origin)
        window.location.replace(targetURL.toString() || '/')
      } else {
        console.log('Ошибка Authorizer')
      }
    }
  },
  mounted() {
    this.preparedAuth()
  }
}
</script>

<style lang="scss">
</style>