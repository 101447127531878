<template>
  <Modal isImage @close="$emit('close')">
    <div class="default">
      <h3 class="win__prize">Упс…</h3>
      <p class="default__text">
        Сегодня не ваш день.
        Не отчаивайтесь, повезет в другой раз.
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";

export default {
  name: "ModalDefeat",
  components: {BaseButton, Modal},
}
</script>

<style lang="scss">
.default {
  max-height: 75%;
  text-align: center;
  padding-bottom: 70px;
  background-image: url("../assets/star-yellow.svg"),
  url("../assets/star-yellow.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: 28px, 20px;
  background-position: top 20% left 2%, bottom 30% right 3%;
  &__title {
    color: #204062;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
  }
  &__prize {
    color: #0167CF;
    font-weight: 600;
    font-size: 60px;
    margin: 30px 0;
  }
  &__text {
    color: #204062;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 40px;
  }
}
.conditions-header {
  font-size: 24px;
}
.button {
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
  @media (max-width: 376px) {
    display: flex;
    justify-content: center;
  }

  .modal-button {
    width: 200px;
  }
}

.link {
  text-decoration: none;
}

</style>