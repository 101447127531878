<template>
	<div class="page page--giveaway">
		<div class="header">
			<div class="img-label">
				<img src="../assets/svg/label.svg" alt="logo"/>
			</div>
			<div class="img-ball">
				<img src="../assets/svg/pink-ball.svg" alt="ball"/>
			</div>
			<div class="img-year">
				<img src="../assets/svg/number.svg" alt="year"/>
			</div>
		</div>
		<div class="content">
			<div class="left-part">
				<div class="title">
					<span>Новогодние подарки от Деда Мороза</span>
					<img src="../assets/svg/bow.svg" alt="bow" class="title-bow"/>
				</div>
				<span class="text">Крутите колесо фортуны и выигрывайте подарки для своих детей</span>
				<div class="image-presents">
					<img src="../assets/svg/presents.svg" alt="presents"/>
				</div>
			</div>
			<div class="right-part">
				<SuperWheel
					:is-sample="false"
					@onComplete="showPrize"
					:is-start="twist"
				/>
			</div>
		</div>
		<div class="mobile-wreath"></div>
		<ModalGiveaway
			v-if="isModal"
			:prize="prize"
			@close="closeModal"
			:cb="onStartWheel"
		/>
		<ModalAddress
			v-if="isModalAddress"
			:is-large="true"
			:prize="prize"
			@onSubmit="submitAddress"
		/>
	</div>
</template>

<script>
import SuperWheel from "@/views/SuperWheel";
import Countdown from "@/components/Countdown";
import Modal from "@/components/Modal";
import ModalGiveaway from "@/components/ModalGiveaway";
import ModalAddress from "@/components/ModalAddress";

export default {
	name: "GiveawayPage",
	components: {ModalAddress, ModalGiveaway, Modal, SuperWheel, Countdown},
	data() {
		return {
			isModal: false,
			isModalAddress: false,
			prize: {
				type: ''
			},
			twist: false
		}
	},
	methods: {
		closeModal() {
			this.isModal = false;
			this.prize = {
				type: '',
			}
		},
		showPrize(e) {
			this.twist = false
			this.prize = {
				...e,
				step: 1,
			};
			if(e.type === 'SWEETS') {
				this.isModalAddress = true;
			} else {
				this.isModal = true;
			}
			
		},
		submitAddress() {
			this.prize.step = 2;
			this.isModalAddress = false;
			this.isModal = true;
		},
		onStartWheel() {
			this.closeModal()
			this.twist = true
		}
	},
}
</script>

<style lang="scss">
.page--giveaway {
	padding: 30px 20px;
	background-image: url("../assets/giveaway-bg-small.svg");
	background-repeat: no-repeat;
	background-size: cover;
	@media (min-width: 680px) {
		background-image: url("../assets/giveaway-bg-medium.svg");
		padding: 30px 50px;
	}
	@media (min-width: 1025px) {
		background-image: url("../assets/giveaway-bg-large.svg");
		padding: 44px 120px;
	}
	
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		.img-label {
			width: 170px;
			height: 100px;
			@media (max-width: 680px) {
				width: 101px;
				height: 61px;
			}
			img {
				@media (max-width: 680px) {
					width: 101px;
					height: 61px;
				}
			}
		}
		
		.img-ball {
			width: 90px;
			height: 90px;
			@media (max-width: 1025px) {
				position: absolute;
				top: 25%;
				right: 15%;
			}
		}
		
		.img-year {
			width: 230px;
			height: 70px;
			@media (max-width: 680px) {
				width: 150px;
				height: 28px;
			}
			img {
				@media (max-width: 680px) {
					width: 150px;
					height: 28px;
				}
			}
			
		}
	}
	
	.content {
		display: flex;
		flex-direction: column;
		@media (min-width: 1025px) {
			flex-direction: row;
		}
		
		.left-part {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 40px;
			@media (min-width: 1025px) {
				width: 55%;
			}
			
			.title {
				position: relative;
				color: white;
				font-size: 56px;
				font-weight: bold;
				padding-bottom: 12px;
				@media (max-width: 680px) {
					font-size: 30px;
				}
				
				&-bow {
					position: absolute;
					width: 3rem;
					height: 3rem;
					top: -1rem;
					left: -1.5rem;
				}
			}
			.text {
				color: white;
				
				padding-right: 0;
				font-size: 16px;
				@media (min-width: 680px) {
					padding-right: 12rem;
					font-size: 20px;
				}
				@media (min-width: 1025px) {
					padding-right: 0;
				}
			}
			.image-presents {
				margin-top: 40px;
				max-width: 480px;
				max-height: 370px;
				display: none;
				@media (min-width: 1025px) {
					display: flex;
				}
			}
		}
		
		.right-part {
			display: flex;
			justify-content: center;
			padding-bottom: 8rem;
			@media (max-width: 680px) {
				display: block;
			}
			@media (min-width: 680px) {
				display: block;
				padding-bottom: 10rem;
			}
			@media (min-width: 1025px) {
				padding-bottom: 0;
			}
		}
		
	}
	.mobile-wreath {
		background-image: url("../assets/svg/mobile-wheel-bg.svg");
		width: 100%;
		height: 98px;
		position: absolute;
		bottom: 0;
		background-repeat: no-repeat;
		left: 0;
		background-size: contain;
		@media (min-width: 680px) {
			width: 100%;
			height: 200px;
			position: absolute;
			background-size: cover;
			background-repeat: no-repeat;
		}
		@media (min-width: 1025px) {
			display: none;
		}
	}
}
</style>
