<template>
	<div class="wrapper">
    <div class="wheel-button"
         @click="onStart"
    >
      <img src="@/assets/wheel-banner.png"
           alt="wheel-banner"
           class="wheel-banner"
      >
      <h2 class="wheel-button__text">Крути колесо Фортуны <br/> и выигрывай приз!</h2>
    </div>
		<div id="superwheel" class="superWheel" @click="onStart"></div>
		<div class="button-wrapper">
			<button
				class="spin-button"
				:disabled="loading || !user.tries"
				@click="onStart"
			>
				Крутить колесо
			</button>
			<span v-if="user.tries" class="description">Для вас {{user.tries | toPlural('подготовлен', 'подготовлено', 'подготовлено')}} {{user.tries}} {{user.tries | toPlural('подарок', 'подарка', 'подарков')}}!</span>
			<span v-if="!user.tries && !loading" class="description">Ваши попытки закончились</span>
		</div>
    <ModalWin
        v-if="isModalWin"
        @close="closeModalWin"
    />
    <ModalDefeat
        v-if="isModalDefeat"
        @close="closeModalDefeat"
    />
    <ModalNoTries
        v-if="isModalNoTries"
        @close="closeNoTries"
    />
    <ModalThanks
        v-if="isModalThanks"
        @close="closeThanks"
    />
	</div>
</template>

<script>
import $ from 'jquery';
import superWheel from "@/components/superWheel/dist/jquery.superwheel.min";
import voice from "@/assets/sound/wheel.mp3"
import Swal from "sweetalert2";
import {mapActions, mapState} from "vuex";
import axios from "axios";
import bow from '@/assets/svg/bow.svg'
import ModalWin from "@/components/ModalWin";
import ModalDefeat from "@/components/ModalDefeat";
import ModalNoTries from "@/components/ModalNoTries";
import ModalThanks from "@/components/ModalThanks";


const test = 'test'

export default {
	name: 'SuperWheel',
  components: {ModalThanks, ModalNoTries, ModalDefeat, ModalWin},
  props: {
		isSample: {
			type: Boolean,
			default: true
		},
		isStart: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			options: {
				slices: [
					{
						text: '<div class="item item-ups">Упс… повезет в другой раз</div>',
						message: '"Упс… повезет в другой раз"',
						value: 4,
						background: '#0056AD'
					},
					{
            text: '<div class="item">1 000 ₽</div>',
            message: '"1 000 ₽"',
            value: 1,
            background: '#54D3FF'
					},
					{
            text: '<div class="item">2 000 ₽</div>',
            message: '"2 000 ₽"',
            value: 2,
            background: '#0056AD'
					},
					{
            text: '<div class="item">3 000 ₽</div>',
            message: '"3 000 ₽"',
            value: 3,
            background: '#54D3FF'
					},
					{
            text: '<div class="item item-ups">Упс… повезет в другой раз</div>',
            message: '"Упс… повезет в другой раз"',
            value: 4,
            background: '#0056AD'
					},
					{
            text: '<div class="item">1 000 ₽</div>',
            message: '"1 000 ₽"',
            value: 1,
            background: '#54D3FF'
					},
					{
            text: '<div class="item">2 000 ₽</div>',
            message: '"2 000 ₽"',
            value: 2,
            background: '#0056AD'
					},
					{
            text: '<div class="item">3 000 ₽</div>',
            message: '"3 000 ₽"',
            value: 3,
            background: '#54D3FF'
					},
					{
            text: '<div class="item item-ups">Упс… повезет в другой раз</div>',
            message: '"Упс… повезет в другой раз"',
            value: 4,
            background: '#0056AD'
					},
					{
            text: '<div class="item">1 000 ₽</div>',
            message: '"1 000 ₽"',
            value: 1,
            background: '#54D3FF'
					},
					{
            text: '<div class="item">2 000 ₽</div>',
            message: '"2 000 ₽"',
            value: 2,
            background: '#0056AD'
					},
					{
            text: '<div class="item">3 000 ₽</div>',
            message: '"3 000 ₽"',
            value: 3,
            background: '#54D3FF'
					},
				],
				text: {
					color: '#ffffff',
					size: 16,
					letterSpacing: 0,
					orientation: 'v',
				},
				marker: {
					background: '#863A3A'
				},
				width: 550,
				selector: 'value',
				center: {width: 35, rotate: false, class: 'wheel-center', background: '#E6E9ED', image: {url: `https://i.ibb.co/0qqT4Z8/wheel-circle.png`, width: 45}},
				inner: {
					width: 0,
				},
				line: {
					width: 0,
					color: "#939393"
				},
			},
			voice: new Audio(voice),
			prize: null,
			message: "",
			email: "",
			loading: false,
      isModalWin: false,
      isModalDefeat: false,
      isModalNoTries: false,
      isModalThanks: false
		}
	},
	computed: {
		...mapState('wheel', ['user']),
		getPrize() {
			if (this.user && this.user.award) {
				const temp = this.options.slices.find(item => item.value === this.user.award);
				if (temp) {
					return temp
				}
			}
			return false;
		}
	},
	methods: {
		...mapActions('wheel', ['changePermission', 'loadUser', "onStartWheel"]),
    async onStart() {
      if(!this.loading) {
        if(this.user && this.user.tries) {
          this.onStartWheel()
              .then((res) => {
                this.loading = true
                $('#superwheel').superWheel('start', res.data.id);
                this.prize = res.data;
                this.$emit('onStart', res)
                $('#superwheel').superWheel('onComplete', async function() {})
                setTimeout(this.openModal,7500, res);
                // if(res.data.id === 4) {
                //   this.isModalDefeat = true
                // } else {
                //   this.isModalWin = true
                // }
              })
              .catch((err) => console.log(err))
              .finally(() => {
                this.loadUser()
              })
        } else {
          this.isModalNoTries = true
          this.loading = false
          this.$emit('isStart', false);
        }
      }
		},
    openModal(res) {
      if(res.data.id === 4) {
        this.isModalDefeat = true
      } else {
        this.isModalWin = true
      }
      this.loading = false
      this.$emit('isStart', false);
    },
		onCompleted(result) {
			const data = {
				id: this.prize.id,
				photoUrl: this.prize.photo_url,
				title: this.prize.title,
				type: this.prize.type,
				message: result.message
			}
			this.loading = false
			this.$emit('onComplete', data);
		},
		showForm() {
			Swal.fire({
				icon: 'success',
				title: "Вы выиграли",
				text: this.getPrize.message || "",
				inputLabel: 'Введите Ваш email',
				inputPlaceholder: 'Введите email',
				input: 'email',
				showCancelButton: true,
				cancelButtonText: 'Закрыть',
				confirmButtonText: 'Отправить',
				showLoaderOnConfirm: true,
				validationMessage: 'Некорректный формат',
				allowOutsideClick: false,
				preConfirm: (email) => {
					const token = localStorage.getItem('token');
					const formData = new FormData();
					formData.append('email', email);
					return axios({
						method: 'post',
						url: 'https://7f62a2b7c89f.ngrok.io/award',
						data: formData,
						headers: {
							'Authorization': 'Bearer ' + token
						},
					})
						.then(() => {
							Swal.fire({
								icon: 'success',
								title: 'Успешно.',
								text: `Наши менеджеры свяжутся с вами для получения приза`
							})
						})
						.catch((error) => {
							Swal.fire({
								icon: 'error',
								title: 'Ошибка.',
								text: error?.response?.data?.error,
							})
						})
						.finally(() => this.getUser())
				}
			})
		},
		async submit(email) {
			const token = localStorage.getItem('token');
			const formData = new FormData();
			formData.append('email', email);
			await axios({
				method: 'post',
				url: 'https://7f62a2b7c89f.ngrok.io/award',
				data: formData,
				headers: {
					'Authorization': 'Bearer ' + token
				},
			})
				.then(() => {
					Swal.fire({
						icon: 'success',
						title: 'Успешно.',
						text: `Наши менеджеры свяжутся с вами для получения приза`
					})
				})
				.catch((error) => {
					Swal.fire({
						icon: 'error',
						title: 'Ошибка.',
						text: error?.response?.data?.error,
					})
				});
			await this.getUser()
		},
		getUser() {
			this.loading = true;
			this.loadUser()
				.finally(() => this.loading = false)
		},
    closeNoTries() {
      this.isModalNoTries = false
    },
    closeModalDefeat() {
      this.isModalDefeat = false
    },
    closeModalWin() {
      this.isModalWin = false
      this.isModalThanks = true
    },
    closeThanks() {
      this.isModalThanks = false
    }
	},
	mounted() {
		const token = localStorage.getItem('token');
		if(token) {
			this.getUser()
		}
		
		$('#superwheel').superWheel({
			...this.options
		})
		
		$('#superwheel').superWheel('onProgress', function (slicePercent, circlePercent) {
		});
		
		$('#superwheel').superWheel('onComplete', this.onCompleted)
	},
	watch: {
		isStart: {
			immediate: true,
			handler(val) {
				if(val) {
					this.onStart()
				}
			},
		},
	},
};
</script>

<style lang="css">

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding-bottom: 2rem;
}
@media (min-width: 1025px) {
	.wrapper {
		padding-bottom: 5rem;
	}
}

.wrapper .superWheel {
	position: relative;
	max-width: 100%;
	margin: 0;
	font-size: 25px;
	font-weight: bold;
	width: 550px;
	height: 550px;
  display: grid;
  justify-content: center;
}

@media (max-width: 680px) {
	.wrapper .superWheel {
		width: 550px;
		height: 550px;
	}
}

.sWheel-wrapper {
  width: 400px !important;
  height: 400px !important;
}

.wheel-banner {
  margin-bottom: 20px;
}

.wheel-button {
  display: flex;
  justify-content: center;
  position: relative;
}
.wheel-button__text {
  font-size: 20px;
  font-weight: 700;
  color: white;
  position: absolute;
  top: 30px;
  text-transform: uppercase;
  text-align: center;
}

.wrapper .superWheel, .superWheel * {
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.sWheel-wrapper {
	position: relative
}

.wrapper .superWheel .sWheel-inner {
	display: block;
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative
}

.wrapper .superWheel .sWheel {
	border-radius: 100%;
	overflow: hidden
}

.wrapper .superWheel .sWheel, .superWheel .sWheel > .sWheel-bg-layer, .superWheel .sWheel > .sWheel-txt-wrap, .superWheel .sWheel > .sWheel-txt-wrap > .sWheel-txt {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%
}

.wrapper .superWheel .sWheel > .sWheel-txt-wrap > .sWheel-txt, .superWheel .sWheel > .sWheel-bg-layer {
	margin: 0 auto;
	border-radius: 100%;
	padding: 0;
	list-style: none;
	overflow: hidden;
	color: #ecf0f1
}

.wrapper .superWheel .sWheel > .sWheel-txt-wrap, .superWheel .sWheel > .sWheel-bg-layer {
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg)
}

.wrapper .superWheel .sWheel .sWheel-child .sWheel-inside {
	display: table;
	-webkit-transform: rotate(0) skew(-45deg);
	transform: rotate(0) skew(-45deg);
	width: 50%;
	height: 50%;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	text-align: right;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	overflow: hidden
}

.wrapper .superWheel .sWheel .sWheel-child .sWheel-inside > div {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
	-webkit-transform: rotate(25deg);
	transform: rotate(25deg);
	-webkit-transform-origin: 115% 25%;
	transform-origin: 115% 25%;
	padding-right: 40px;
	font-size: 18px;
	font-weight: 700
}

.wrapper .superWheel .sWheel > .sWheel-bg-layer > div {
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	border: 1px solid transparent;
	background-color: #404040
}

.superWheel .sWheel > .sWheel-bg-layer > div:nth-child(odd) {
	background-color: #616161
}

.wrapper .superWheel .sWheel > .sWheel-txt-wrap > .sWheel-txt > div {
	position: absolute;
	top: 50%;
	left: 50%;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	line-height: 1.2em;
	max-height: 23.4em;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 1px;
	width: 50%;
	padding-right: 6%;
	font-weight: 700;
	font-size: 100%;
	cursor: default;
	color: #fff;
	text-align: right
}

.superWheel .sWheel > .sWheel-txt-wrap > .sWheel-txt > div img {
	max-width: 40%
}

.wrapper .superWheel .sWheel-center {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	text-align: center
}

.wrapper .superWheel .sWheel-center > .sw-center-empty, .superWheel .sWheel-center > .sw-center-html {
	max-width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	z-index: 2
}

.wrapper .superWheel .sWheel-center > .sw-center-empty {
	position: absolute
}

.wrapper .superWheel .sWheel-center > img {
	max-width: 100%;
	width: 200px;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.wrapper .superWheel .sWheel-center > div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent !important
}

.wrapper .superWheel .sWheel-marker {
	border-spacing: 90px;
	width: 16%;
	height: 14%;
	position: absolute;
	left: 50%;
	top: -18%;
	margin-top: 7%;
	margin-left: -8%;
	-webkit-transition: .2 rotate linear;
	transition: .2 rotate linear;
	z-index: 1;
	display: block;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transform-origin: 50% 35%;
	transform-origin: 50% 35%
}

.wrapper .superWheel .sWheel-marker > svg {
	height: 100%;
	display: none;
	text-align: center;
	margin: 0 auto
}

.wrapper .superWheel .rotate {
	-webkit-transform: rotate(100deg);
	transform: rotate(100deg)
}

.wrapper .sWheel > .sWheel-bg-layer > svg {
	margin: 0 auto;
	border-radius: 50%;
	display: block;
	width: 100%;
	height: 100%;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}

.sWheel-title {
  padding-left: 5rem;
}

@-webkit-keyframes animateColor {
	0% {
		background-position: 0 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0 50%
	}
}

@keyframes animateColor {
	0% {
		background-position: 0 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0 50%
	}
}





</style>
