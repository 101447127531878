<template>
	<div class="input-wrapper">
		<span class="label">{{label}}</span>
		<input
			class="input-field"
			:placeholder="placeholder"
			:disabled="disabled"
			:value.sync="model"
			@change="change"
			@input="change"
			@blur="$emit('onBlur')"
			@focus="$emit('onFocus')"
		/>
		<span class="error-message">{{error}}</span>
	</div>
</template>

<script>
import VModelMixin from "@/common/mixins/VModelMixin";

export default {
	name: "BaseInput",
	mixins: [VModelMixin],
	props: {
		label: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		error: {
			type: String,
			default: ''
		}
	},
}
</script>

<style lang="scss" scoped>
.input-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 1rem;
	.label {
		margin-bottom: 5px;
		color: #828282;
		font-size: 14px;
	}
	.input-field {
		padding: 0.75rem;
		border: 0;
		border-radius: 4px;
		background: #F8F9FB;
		&:focus {
			outline: 0 !important;
			outline-offset: 0 !important;
			border: 0 !important;
			box-shadow: none !important;
		}
	}
	.error-message {
		position: absolute;
		color: red;
		font-size: 0.75rem;
		bottom: 0;
	}
}
</style>
