<template>
	<Modal
		class="modal-giveaway"
		:is-large="isLarge"
		:closable="false"
	>
		<template
			v-if="!isLarge"
			v-slot:header
		>
			<div class="header">
				{{prize.step === 1 ? 'Поздравляем' : 'Данные отправлены'}}
				<img
					src="../assets/svg/confetti.svg"
					alt="confetti"
					class="header-image"
				/>
			</div>
		</template>
		<div
			class="modal-giveaway--content"
		>
			<div class="present-image">
				<img :src="prize.photoUrl" class="image" alt="prize"/>
			</div>
			<div v-if="prize.step === 2" class="description">
				Мы отправим <span class="text-primary">{{prize.message}}</span> в ближайший пункт выдачи
			</div>
			<div v-else class="description">
				Вы выиграли <span class="text-primary">{{prize.message}}</span>. Мы отправим вам подарок на email, указанный при регистрации
			</div>
			<div v-if="user.tries" class="tries">
				Для вас {{user.tries | toPlural('остался', 'осталось', 'осталось')}} ещё {{user.tries}} {{user.tries | toPlural('подарок', 'подарка', 'подарков')}}
			</div>
			<base-button
				class="modal-button"
				@click=onClose
			>
				{{user.tries ? 'Крутить еще раз' : 'Отлично'}}
			</base-button>
		</div>
	</Modal>
</template>

<script>
import Modal from "@/components/Modal";
import AddressForm from "@/components/AddressForm";
import BaseButton from "@/components/BaseButton";
import {mapState} from "vuex";

export default {
	name: "ModalGiveaway",
	components: {BaseButton, AddressForm, Modal},
	props: {
		isLarge: {
			type: Boolean,
			default: false,
		},
		prize: {
			type: Object,
			default: () => {}
		},
		cb: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		...mapState('wheel', ['user']),
	},
	methods: {
		onClose() {
			if(this.user.tries) {
				this.cb()
			} else {
				this.$emit('close')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.modal-giveaway {
	
	.header {
		position: relative;
		margin: 0 auto;
		&-image {
			position: absolute;
			width: 3rem;
			height: 3rem;
			top: -1.5rem;
			right: -2.5rem;
			@media (max-width: 376px) {
				width: 2rem;
				height: 2rem;
				top: -0.5rem;
				right: -1.5rem;
			}
		}
	}
	.content-column {
		display: flex;
		background-repeat: no-repeat;
		padding-bottom: 2rem;
		.modal-bg {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 0;
			color: white;
		}
		
		.left-part {
			padding-right: 2rem;
			max-width: 50%;
			border-right: 1px solid #e6e6e6;
			z-index: 10;
			&--header {
				margin: 0 auto;
				position: relative;
				font-size: 2rem;
				padding-bottom: 40px;
				.header-image {
					position: absolute;
					top: -3.5rem;
				}
			}
			.present-image {
				margin: 0 auto;
				max-height: 175px;
				max-width: 220px;
				width: 100%;
				height: 100%;
			}
			.description {
				text-align: center;
				padding-top: 1rem;
			}
		}
		.right-part {
			width: 50%;
			padding-left: 40px;
		}
	}
	&--content {
		display: flex;
		flex-direction: column;
		align-items: center;
		.present-image {
			height: 175px;
			width: 100%;
			max-height: 175px;
			max-width: 170px;
		}
		.description {
			margin-top: 1rem;
			padding-left: 2rem;
			padding-right: 2rem;
			text-align: center;
			& + .base-button {
				margin-top: 50px;
			}
		}
		.modal-button {
			width: 200px;
		}
	}
	.text-primary {
		font-weight: bold;
		color: #8321FF;
	}
	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.tries {
		display: flex;
		justify-content: center;
		font-size: 1rem;
		font-weight: bold;
		padding-top: 2.25rem;
		padding-bottom: 1rem;
		border-top: 1px solid #e6e6e6;
		margin-top: 2.5rem;
		
	}
}

</style>
