<template>
<button
	class="base-button"
	:disabled="disabled"
	:class="classes"
	@click="$emit('click')"
>
	<slot></slot>
</button>
</template>

<script>
export default {
	name: "BaseButton",
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'primary',
		}
	},
	computed: {
		classes() {
			return {
				'base-button--primary': this.type === 'primary',
				'base-button--disabled': this.disabled,
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.base-button {
	font-size: 16px;
	display: flex;
	justify-content: center;
	padding-top: 12px;
	padding-bottom: 12px;
	cursor: pointer;
	outline: none;
	&--primary {
		color: white;
		background: #0167CF;
		border-radius: 50px;
		border: 0;
	}
	&--disabled {
		background: #D5B3FF;
		cursor: default;
	}
}
</style>
