<template>
	<Modal @close="$emit('close')">
		<template v-slot:header>
			Регистрация
		</template>
		<span class="text">Для участия в розыгрыше, введите ваши данные</span>
		<div class="form">
			<base-input
				v-model="form.name"
				class="form-field"
				label="ФИО"
				placeholder="Иванов Иван Иванович"
				:error="errors.name"
				@onFocus="clearError('name')"
			/>
			<base-input
				v-model="form.email"
				class="form-field"
				label="Рабочий email"
				placeholder="email@gmail.com"
				:error="errors.email"
				@onFocus="clearError('email')"
			/>
		</div>
		<div class="button">
			<BaseButton
				:disabled="!form.name || !form.email"
				class="modal-button"
				type="primary"
				@click="onSubmit()"
			>
				Зарегистрироваться
			</BaseButton>
		</div>
	</Modal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import BaseInput from "@/components/BaseInput";
import {validEmail} from "@/common/helpers/validate";
import {mapActions} from "vuex";

export default {
	name: "ModalLogin",
	components: {BaseInput, BaseButton, Modal},
	data() {
		return {
			form: {
				email: '',
				name: '',
			},
			errors: {
				email: '',
				name: '',
			}
		}
	},
	methods: {
		...mapActions('wheel', ['login']),
		onSubmit() {
			if(!validEmail(this.form.email)) {
				this.errors.email = 'некорректный адрес эл.почты'
			}
			else if(!this.form.name) {
				this.errors.name = 'укажите ФИО'
			} else {
				this.login(this.form)
					.then(() => {
							if(localStorage.getItem('token')) {
								this.$emit('close')
							}
						}
					)
					.catch((err) => {
						this.errors.email = err?.response?.data?.error
					})
			}
		},
		clearError(field) {
			if(field && field in this.errors) {
				this.errors[field] = ''
			}
		}
	},
}
</script>

<style lang="scss" scoped>

.button {
	padding-top: 5rem;
	.modal-button {
		width: 200px;
		@media (max-width: 680px) {
			margin: 0 auto;
		}
	}
	
}
.text {
	font-size: 14px;
	color: #828282;
}
.form {
	padding-top: 1.5rem;
	&-field + .form-field {
		margin-top: 0.5rem;
	}
}
</style>
