<template>
	<div class="countdown">
		<div class="box">
			<div class="value">
				<div class="digit">{{ days[0] }}</div>
				<div class="digit">{{ days[1] }}</div>
			</div>
			<div class="caption">{{days | toPlural('День', 'Дня', 'Дней')}}</div>
		</div>
		<div class="separator">:</div>
		<div class="box">
			<div class="value">
				<div class="digit">{{ hours[0] }}</div>
				<div class="digit">{{ hours[1] }}</div>
			</div>
			<span class="caption">{{hours | toPlural('Час', 'Часа', 'Часов')}}</span>
		</div>
		<div class="separator">:</div>
		<div class="box">
			<div class="value">
				<div class="digit">{{ minutes[0] }}</div>
				<div class="digit">{{ minutes[1] }}</div>
			</div>
			<span class="caption">{{minutes | toPlural('Минута', 'Минуты', 'Минут')}}</span>
		</div>
		<div v-if="showSec" class="separator">:</div>
		<div v-if="showSec" class="box" >
			<div class="value">
				<span class="digit">{{ seconds[0]}}</span>
				<span class="digit">{{ seconds[1]}}</span>
			</div>
			<span class="caption">{{seconds | toPlural('секунда', 'секунды', 'секунд')}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "Countdown",
	props: {
		showSec: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			now: Math.trunc((new Date()).getTime() / 1000),
			date: process.env.VUE_APP_TIME_VALUE || "2024-01-30T23:59:59.999Z"
		}
	},
	computed: {
		dateInMilliseconds() {
			return Math.trunc(Date.parse(this.date) / 1000)
		},
		seconds() {
			const temp = (this.dateInMilliseconds - this.now) % 60;
			return this.$options.filters.twoDigits(temp).toString()
		},
		minutes() {
			const temp = Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
			return this.$options.filters.twoDigits(temp).toString()
		},
		hours() {
			const temp = Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
			return this.$options.filters.twoDigits(temp).toString()
		},
		days() {
			const temp = Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
			return this.$options.filters.twoDigits(temp).toString()
		}
	},
	mounted() {
		window.setInterval(() => {
			this.now = Math.trunc((new Date()).getTime() / 1000);
		},1000);
	},
}
</script>

<style lang="scss" scoped>
.countdown {
	display: flex;
	align-items: center;
	padding-bottom: 1rem;
	.box {
		display: flex;
		flex-direction: column;
		position: relative;
		.value {
			display: flex;
			flex: 1 0 auto;
			.digit {
				color: #209A1E;
				font-size: 30px;
				background: white;
				border-radius: 4px;
				padding: 7px 9px;
				width: 18px;
				font-weight: bold;
				& + .digit {
					margin-left: 2px;
				}
			}
		}
		
		.caption {
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			top: 100%;
			color: white;
			text-align: center;
			margin-top: 2px;
			font-weight: bold;
		}
	}
	.separator {
		margin: 0 0.25rem 0.25rem;
		font-size: 30px;
		font-weight: bold;
		display: block;
		color: white;
	}
}
</style>
